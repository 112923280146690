import React, { useCallback } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { SignInPage, AIJobsPage } from '@/pages'
import { signInPath, superhumanPath } from '@/utils'
import { TokenWrapper, AuthWrapper, AppLayout } from '@/layout'

export const AppRoutes: React.FC = () => {
  const onError = useCallback((e: Error) => {
    console.log({ appError: e })
  }, [])

  return (
    <ErrorBoundary onError={onError} fallback={<div />}>
      <Routes>
        <Route element={<TokenWrapper />}>
          <Route path={signInPath} element={<SignInPage />} />
          <Route element={<AuthWrapper />}>
            <Route element={<AppLayout />}>
              {/*<Route path={homePath} element={<DashboardPage />} />*/}
              {/*<Route path={appointmentsPath} element={<AppointmentsPage />} />*/}
              {/*<Route path={leadsPath} element={<LeadsPage />} />*/}
              {/*<Route path={organizationsPath} element={<OrganizationPage />} />*/}
              <Route path={superhumanPath} element={<AIJobsPage />} />
              <Route path="*" element={<Navigate to={superhumanPath} />} />
              {/*<Route*/}
              {/*  path={aiBotCustomersPath}*/}
              {/*  element={<AIBotCustomersPage />}*/}
              {/*/>*/}
              {/*<Route path={accessControlPath} element={<AccessControlPage />} />*/}
              {/*<Route path={a2pPath + a2pPathSection} element={<A2p />} />*/}
              {/*<Route path={lockedLocationPath} element={<LockedLocation />} />*/}
              {/*<Route path={inventoryPath} element={<InventoryPage />} />*/}
              {/*<Route path={userSettingsPath} element={<UserSettings />} />*/}
            </Route>
            {/*<Route element={<V2DashboardLayout />}>*/}
            {/*  <Route path={dashboardV2Path} element={<DashboardV2Page />} />*/}
            {/*  <Route path={workflowPath} element={<WorkflowPage />} />*/}
            {/*</Route>*/}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to={signInPath} />} />
      </Routes>
    </ErrorBoundary>
  )
}
