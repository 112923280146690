import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { superhumanPath } from '@/utils'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Button, TextLink } from '@/components'
import { XMarkIcon } from '@heroicons/react/24/outline'

interface IMobileMenuProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const MobileMenu = ({ open, setOpen }: IMobileMenuProps) => {
  return (
    <div className="flex flex-col gap-3">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="flex items-center justify-between w-full">
                            <TextLink
                              to={superhumanPath}
                              className="-m-1.5 p-1.5"
                            >
                              <img
                                alt=""
                                className="h-6 w-auto"
                                src="https://matador-crm.vercel.app/logo-01.png"
                              />
                            </TextLink>
                            <Button
                              type="button"
                              className="-m-2.5 rounded-md p-2.5 text-gray-700"
                              onClick={() => setOpen(false)}
                            >
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex flex-col gap-3 h-full px-4 sm:px-6">
                        <NavLink
                          to={superhumanPath}
                          className={({ isActive }) =>
                            classNames(
                              'text-base font-semibold leading-6 text-gray-900 px-2',
                              {
                                'text-indigo-600': isActive,
                              },
                            )
                          }
                        >
                          Superhuman
                        </NavLink>

                        <div className="mt-auto">
                          <Button
                            // @ts-ignore
                            onClick={window.onSignOut}
                            className="btn-danger -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Log out
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default MobileMenu
