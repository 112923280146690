import React, { useEffect, useState } from 'react'
import { Bars3Icon, BellAlertIcon } from '@heroicons/react/24/outline'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { superhumanPath } from '@/utils'
import { Button, TextLink } from '@/components'
import { useDashboard } from '@/hooks'
import MobileMenu from '@/layout/AppHeader/components/mobileMenu'

export const AppHeader: React.FC = () => {
  const location = useLocation()
  const { notifications } = useDashboard()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location])

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex items-center gap-x-12">
          <TextLink to={superhumanPath} className="-m-1.5 p-1.5 shrink-0">
            <img
              alt=""
              className="h-6 w-auto"
              src="https://matador-crm.vercel.app/logo-01.png"
            />
          </TextLink>

          <div className="hidden items-center xl:flex gap-x-4 xl:gap-x-6 2xl:gap-x-12">
            <NavLink
              to={superhumanPath}
              className={({ isActive }) =>
                classNames('text-sm font-semibold leading-6 text-gray-900', {
                  'text-indigo-600': isActive,
                })
              }
            >
              Superhuman
            </NavLink>
          </div>
        </div>
        <div className="flex xl:hidden gap-5">
          <Button
            hasDefaultStyles={false}
            disabled={!notifications.length}
            // @ts-ignore
            onClick={window?.toggleNotifications}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            <BellAlertIcon
              className={classNames('w-5 h-5 text-gray-500', {
                'text-orange-600': notifications.some(_v => !_v.acknowledged),
              })}
            />
          </Button>
          <Button
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Button>
        </div>
        <div className="hidden justify-end xl:flex gap-5 w-1/2">
          <Button
            hasDefaultStyles={false}
            disabled={!notifications.length}
            // @ts-ignore
            onClick={window?.toggleNotifications}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            <BellAlertIcon
              className={classNames('w-5 h-5 text-gray-500 shrink-0', {
                'text-orange-600': notifications.some(_v => !_v.acknowledged),
              })}
            />
          </Button>
          <Button
            // @ts-ignore
            onClick={window.onSignOut}
            className="btn-danger max-w-max text-sm font-semibold leading-6 text-gray-900 shrink-0 !py-[9px]"
          >
            Log out{' '}
            <span className="ml-2" aria-hidden="true">
              &rarr;
            </span>
          </Button>
        </div>
      </nav>
      <MobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
    </header>
  )
}
